#newUserDropDown {
    width: 700px !important;
    border: none !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    padding: 10px 15px !important;
    position: absolute;
    z-index: 10;
    top: 55px;
    right: -10px;
    background-color: white;
}

#newUserBtnContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1%;
    padding-top: 2%;
    border-top: 1px solid rgb(209, 207, 207);
}

#newUserBtnContainer>button {
    font-size: x-small !important;
    font-family: 'GothamBook',sans-serif;
    border-radius: 32px 32px 32px 32px;
    width: 45%;
    padding: 10px 0px;
}

#headText {
    font-size: x-small !important;
    font-weight: 1000 !important;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px 0px 5px 0px;
    color: black;
}

#customTable {
    max-height: 110px;
    overflow: hidden;
}

#customTable:hover {
    overflow: auto;
}

#customTableHead {
    width: 100%;
    background-color: white;
    color: grey;
    border-bottom: 1px solid rgb(209, 207, 207);
}


#customTableHead tr th {
    padding-left: 0px;
}

#customTableBody tr td {
    font-size: xx-small !important;
    color: grey;
    padding: 1% 0%;
}

#customTableBody tr td i {
    font-size: small !important;
}

#newUserDropDown>#body>#row p {
    font-size: x-small !important;
    margin: 0px 0px 7px 0px;
    color: grey;
}

#newUserDropDown>#body>#row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#newUserDropDown>#body>#row>#rightCol {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#newUserDropDown>#body>#row>#rightCol>#rcDOB {
    font-size: 8px !important;
    margin: 0px 0px 7px 2px;
    color: grey;
}

#topBarBtn {
    border: 1px solid transparent;
    background-color: transparent;
    padding: 5px;
    border-radius: 32px;
}

#topBarBtn:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
}

#topBarBtn:focus {
    outline: none;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
}

#badge {
    position: absolute;
    font-size: x-small;
    padding: 1px 5px;
    border-radius: 50%;
    background: red;
    color: white;
}

#customTable::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 10px;
}

#customTable::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

#customTable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(224, 224, 224);
}

@media only screen and (max-width: 768px) {

    /* For medium devices: */
    #newUserDropDown {
        width: 300px !important;
        border: none !important;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
        padding: 10px 15px !important;
        margin-left: 0px;

    }

    #customTable {
        overflow: auto;
    }

}