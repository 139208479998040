.table {
  width: 100% !important;
  border-radius: 10px !important;
  max-height: 80vh !important;
}
.tableInner thead {
  position: sticky !important;
  top: 0% !important;
  z-index: 2 !important;
}

.main {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 2%;
}
.tdModerate {
  width: 5%;
  vertical-align: middle !important;
  font-size: x-small !important;
}

.reportsTd {
  vertical-align: middle !important;
}

.td {
  width: 4%;
  vertical-align: middle !important;
  font-size: x-small !important;
}

.tdLess {
  width: 2%;
  vertical-align: middle !important;
  font-size: x-small !important;
}
.tdLess:hover,
.td:hover {
  cursor: pointer;
}
.tableRow {
  text-align: center;
}
.fullWidth {
  text-align: center;
}
.badgeDot {
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 50%;
  color: #2299c5;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  margin: 0px 0px 3px 0px;
}
