.conversationListItem {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .conversationListItem:hover {
    background: #eeeef1;
    cursor: pointer;
  }
  
  .conversationPhoto {
    margin-right: 10px;
  }
  
  .conversationTitle {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
  }
  
  .conversationSnippet {
    font-size: 14px;
    color: #888;
    margin: 0;
  }
  