.usersCont {
  background-color: #c7e1f7a1;
  backdrop-filter: blur(20px);
  opacity: 0.9;
  box-shadow: none;
}

#customTableBody {
  background-color: transparent;
}

#customTableBody td {
  padding: 0 !important;
}

#customLabel {
  color: black !important;
  margin: 5px 0px !important;
  font-weight: normal !important;
}
#customLabelMask {
  margin: 0 5px !important;
  font-size: xx-small !important;
  font-weight: bold !important;
  color: black !important;
}
#selector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: #838a8a;
  font-size: small;
  font-family: 'GothamBook', sans-serif;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}
.comboboxWidth {
  width: 50%;
  margin-left: 1%;
}

#selectedSelector {
  border: 1px solid rgba(221, 221, 221, 1);
  color: black;
  font-family: 'GothamBook', sans-serif;
  font-size: small;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selector:focus {
  outline: none;
  box-shadow: none;
}

#selectedSelector:focus {
  outline: none;
  box-shadow: none;
}

.widthCustomMask {
  width: 32%;
  margin-left: 1%;
}
#customButton {
  border-radius: 32px;
  background-color: transparent;
  color: black;
  border: 1px solid rgb(218, 218, 218);
}

#customButtonSelected {
  border-radius: 32px;
  background-color: #1699c5;
  color: white;
}

#collapseButton:focus {
  box-shadow: none;
}

#timeLogsLink {
  color: black;
  font-size: medium;
  font-weight: 600;
  margin: 0;
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 32px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
