.statsBorder {
  width: '180px';
  height: '180px';
  display: 'block';
  margin: '14px auto';
  text-align: 'center';
  padding: '2%';
  border: '10px solid grey';
  border-radius: '100px';
}

#colorGrading {
  border-image: linear-gradient(to right, green, lightgreen);
  border-image-slice: 1;
  border: 10px solid transparent;
}

.gradientBorder {
  background: #238bcd;
  background: conic-gradient(#238bcd 0deg, #8808bc 90deg, #e73948 200deg, #238bcd 300deg);
  height: max-content;
  width: max-content;
  padding: 12px;
  border-radius: 50%;
  margin: auto;
}
.innerContent {
  background: white;
  text-align: center;
  padding: 27px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
}