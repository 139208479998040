#main {
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border-radius: 15px;
  padding-top: 2%;
}

thead {
  font-size: xx-small;
  background-color: #1699c5;
  color: white;
  font-family: 'GothamBook', sans-serif;
}

.tdTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-width: 20.5vw;
}

td {
  font-size: small;
  font-weight: 600;
}

th {
  font-weight: 500;
}

button {
  background-color: transparent;
  border: none;
}

tbody {
  background-color: white;
}

#rowsPerPage {
  border: none;
  font-weight: 600;
}

#rowsPerPage:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#rowsPerPage:focus {
  outline: none;
}

#customTableFooterText {
  font-size: small !important;
  color: black;
  margin: 0% 1% !important;
}

#customPageinationButton {
  border: none;
  color: black;
  padding: 12px 16px;
  font-size: large;
  border-radius: 50%;
}

#customPageinationButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#customPageinationButton:focus {
  outline: none;
}

#customPageinationButtonDisbaled {
  border: none;
  color: rgb(190, 190, 190);
  padding: 12px 16px;
  font-size: large;
  border-radius: 50%;
}

#customPageinationButtonDisabled:focus {
  outline: none;
}

#customPageinationButton1 {
  border: none;
  color: black;
  padding: 12px 18px;
  font-size: large;
  border-radius: 50%;
}

#customPageinationButton1:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#customPageinationButton1:focus {
  outline: none;
}

#customPageinationButton1Disabled {
  border: none;
  color: rgb(190, 190, 190);
  padding: 12px 18px;
  font-size: large;
  border-radius: 50%;
}

#customPageinationButton1Disabled:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  /* For medium devices: */
  #main {
    margin-top: 5%;
  }
}
