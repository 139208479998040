#footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#footerText {
  font-size: 10px;
  color: rgba(119, 126, 131, 1);
  font-family: 'GothamBook', Arial, sans-serif;
  padding: 0% 0% 1% 0%;
}
#footerUdi {
  font-size: 10px;
  color: rgba(119, 126, 131, 1);
  font-family: 'GothamBook', Arial, sans-serif;
}
#divider {
  width: 80%;
}
