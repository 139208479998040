.compose {
    padding: 10px;
    display: flex;
    align-items: center;
    background: rgb(244, 244, 248);
    border-top: 1px solid #eeeef1 !important;
    width: 95%;
    bottom: 0px;
    border-radius: 30px;
    margin-left: 2%;
}

.composeView {
    padding: 10px;
    display: flex;
    align-items: center;
    background: rgb(244, 244, 248);
    border-top: 1px solid #eeeef1 !important;
    width: 95%;
    bottom: 0px;
    border-radius: 30px;
    margin-left: 2%;
}

@supports (backdrop-filter: blur(20px)) {
    .compose {
        border: none;
        backdrop-filter: blur(20px);
    }
}

.composeInputs {
    width: 92%;
    border: none;
}

.composeInput {
    flex: 10;
    border: none;
    font-size: 14px;
    top: 0;
    bottom: 20px;
    height: 40px;
    background: none;
    max-height: 320px;
    overflow-y: auto;
}

.composeInput div:nth-child(2) {
    top: unset !important;
    bottom: 20px !important;
    max-height: 320px;
    overflow-y: auto;
}

#composeInputBtn {
    display: flex;
    justify-content: center;
    flex: 8;
    width: 8%;
    margin-top: -3px;
}


.compose #toolbarButton {
    color: #007aff;
    margin-left: 15px;
    font-size: 28px;
    transition: all 0.1s;
}

.compose #toolbarButton:hover {
    color: #0063ce;
    cursor: pointer;
}

.compose #toolbarButtonDisabled {
    color: #bbbbbf;
    margin-left: 15px;
    font-size: 28px;
    transition: all 0.1s;
}

.compose #toolbarButtonDisabled:hover {
    color: #99999c;
    cursor: pointer;
}