#AvatorDropDown {
  border: none !important;
  box-shadow: 0 2px 6px 0 #0000001a, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  width: 250px;
  margin-right: 6px;
  position: absolute;
  right: 0%;
}




.menuArrow {
  top: -18px;
  left: 90%;
  width: 0;
  height: 0;
  position: relative;
}

.menuArrow:before,
.menuArrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}

.menuArrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #fff;
}

.menuArrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

#row:hover {
  cursor: pointer;
}

#avator {
  height: 50px;
  width: 50px;
  border: 3px solid white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50px;
  padding: 0;
}

#avator:focus {
  outline: none;
}

#avator>img {
  border-radius: 50%;
}