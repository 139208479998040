.conversationSearch {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.conversationSearchInput {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}
