.table {
  width: 100% !important;
  border-radius: 10px !important;
  max-height: 50vh !important;
}
.tableInner thead {
  position: sticky !important;
  top: 0% !important;
  z-index: 2 !important;
}
.tableHead {
  font-weight: 600;
  vertical-align: middle !important;
  text-align: center;
}
.errorAnimation {
  animation: colorTransition 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

.tableBodyRow {
  text-align: center;
}

@keyframes colorTransition {
  0% {
    background-color: #1699c5;
  }
  100% {
    background-color: red;
  }
}
